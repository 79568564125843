@import url(https://cdnjs.cloudflare.com/ajax/libs/mapbox-gl/2.8.0-alpha.1/mapbox-gl.min.css);
@import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

body,
html {
  background-color: var(--bs-body-color);
  overflow-x: hidden;
}

.info-usage-btn {
  color: #02c7c7;
  cursor: pointer;
  position: absolute;
  top: 1%;
  right: 1%;
}

.bg-dark-input,
#road-typeahead,
#int1-typeahead,
#int2-typeahead,
#async-road-search,
.dropdown-item {
  background-color: var(--bs-gray-800) !important;
  color: rgb(var(--bs-light-rgb)) !important;
}

.form-control {
  background-color: var(--bs-gray-800) !important;
  color: rgb(var(--bs-light-rgb)) !important;
}

.form-check-input {
  background-color: var(--bs-gray-500) !important;
}

.form-check-input:checked {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}

.items-per-pg-dropdown {
  padding-left: 1em;
}

.page-link {
  position: relative;
  display: block;
  color: var(--bs-yellow) !important;
  text-decoration: none;
  background-color: transparent !important;
  border: 1px solid var(--bs-yellow) !important;
  box-shadow: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-item.active .page-link {
  box-shadow: none !important;
  background-color: var(--bs-yellow) !important;
  color: black !important;
}

.page-item.disabled .page-link {
  color: var(--bs-gray-500) !important;
}

.pg-count {
  font-size: small !important;
  color: var(--bs-yellow) !important;
  padding-bottom: 0.3em;
  font-style: italic;
}

.nav-tabs .nav-link {
  color: var(--bs-yellow) !important;
  background-color: transparent !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  margin-bottom: -5px !important;
}

.nav-tabs .nav-link.active {
  border: solid 1px var(--bs-yellow) !important;
}

.nav-link:hover {
  border-bottom: solid 2px var(--bs-yellow) !important;
}

.nav-tabs {
  border-bottom: 1px solid var(--bs-yellow) !important;
}

.outer-box {
  padding: 0.2em;
  background-color: var(--bs-body-color);
}

.outlined {
  border: solid 1px var(--bs-yellow) !important;
  border-radius: 5px;
}

.outlined-top {
  border-top: solid 1px var(--bs-yellow) !important;
  padding-bottom: 0.7em !important;
}

.pb-07 {
  padding-bottom: 0.7em !important;
}

.text-small {
  font-size: small !important;
}

.filter-item {
  display: flex;
}

.dropdown-label {
  padding-right: 0.2em;
}

.modal-content {
  background-color: var(--bs-body-color) !important;
}

.modal-header {
  border-bottom: 1px solid var(--bs-yellow) !important;
}

.modal-footer {
  border-top: 1px solid var(--bs-yellow) !important;
}

.btn-close {
  background-color: var(--bs-yellow) !important;
}

.map-wrapper {
  position: relative;
}

.map {
  width: 100%;
}

.mini-map {
  height: 65vh;
  width: 100%;
}

.header-title {
  margin-left: 2em;
}

.factor-label {
  float: left;
  padding-right: 1em;
}

#legend {
  background-color: rgb(255, 255, 255, 0.9);
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: absolute;
  z-index: 1;
  width: 19em;
  margin: 1em;
  top: 1%;
  font-size: small;
  color: black;
}

.legend h5 {
  margin: 0 0 10px;
}

.legend div span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}

.legend .explaination {
  margin-top: 1em;
}

.basemap-options {
  font-size: small;
}

.spinner {
  height: 5em !important;
  width: 5em !important;
}

.dropdown-label {
  float: left;
  margin-right: 0.5em;
}

.tooltip > div.tooltip-inner {
  background-color: var(--bs-gray-800) !important;
  color: rgb(var(--bs-light-rgb)) !important;
}

.logo {
  background-color: var(--bs-gray-400);
  border-radius: 7px;
  margin-right: 1em;
  padding: 3px;
}

.info-splash-modal {
  max-width: 70% !important;
  max-height: 95% !important;
}

.workflow-img {
  background-color: var(--bs-gray-300);
  border-radius: 7px;
  margin: 1em;
  max-width: 92%;
}

.side-by-side-modal {
  padding-top: 1em;
  display: flex;
}

.arrows-icon {
  margin: 2em;
}

.paragraph-indented {
  margin-left: 3em;
  max-width: 90%;
}

.margin-sides-2em {
  margin-left: 2em;
  margin-right: 2em;
}

.switch-handle {
  background-color: var(--bs-gray-800) !important;
  border-color: var(--bs-gray-800) !important;
}

.dropdown-menu-dark {
  max-height: 300px;
  overflow-y: scroll;
}

.dropdown-menu {
  background-color: var(--bs-gray-800) !important;
}

.custom-tooltip {
  background-color: rgba(0, 0, 0, 0.85);
  padding: 0.5em;
  border-radius: 6px;
}

.individual-crit-badge {
  width: 8rem;
  height: 5rem;
  padding: 0.75rem;
  border-radius: 15px;
  font-weight: bold;
  text-align: center;
  background: black;
  border: 2px solid;
  margin-left: auto;
}

.side-by-side {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side-by-side-left {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 3em;
}

.individual-tbl-header {
  text-align: right;
  vertical-align: top;
}

.pl-1em {
  padding-left: 1em;
}

.underlined {
  text-decoration: underline;
}

.left-justified {
  text-align: left;
}

.report-head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.mr-1em {
  margin-right: 1em;
}
